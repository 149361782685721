.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Custom Properties, update these for your own design */

:root {
    --ff-primary: 'Source Sans Pro', sans-serif;
    --ff-secondary: 'Source Code Pro', monospace;
    
    --fw-reg: 300;
    --fw-bold: 900;
    
    --clr-light: #fff;
    --clr-dark: #303030;
    --clr-accent: #16e0bd;
    
    --fs-h1: 3rem;
    --fs-h2: 2.25rem;
    --fs-h3: 1.25rem;
    --fs-body: 1rem;
    
    --bs: 0.25em 0.25em 0.75em rgba(0,0,0,.25),
          0.125em 0.125em 0.25em rgba(0,0,0,.15);
}

@media (min-width: 800px) {
    :root {
        --fs-h1: 4.5rem;
        --fs-h2: 3.75rem;
        --fs-h3: 1.5rem;
        --fs-body: 1.125rem;
    }
}

/* General styles */

/* endable this to add smooth scrolling */
/* html {
    scroll-behavior: smooth;
} */


body {
    background: var(--clr-light);
    color: var(--clr-dark);
    margin: 0;
    font-family: var(--ff-primary);
    font-size: var(--fs-body);
    line-height: 1.6;
}

section {
    padding: 5em 2em;
}

img {
    display: block;
    max-width: 100%;
}

strong { font-weight: var(--fw-bold) }

:focus {
    outline: 3px solid var(--clr-accent);
    outline-offset: 3px;
}

/* Buttons */

.btn {
    display: inline-block;
    padding: .5em 2.5em;
    background: var(--clr-accent);
    color: var(--clr-dark);
    text-decoration: none;
    cursor: pointer;
    font-size: .8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: var(--fw-bold);
    transition: transform 200ms ease-in-out;
}

.btn:hover {
    transform: scale(1.1);
}

/* Typography */

h1,
h2,
h3 {
    line-height: 1;
    margin: 0;
}

h1 { font-size: var(--fs-h1) }
h2 { font-size: var(--fs-h2) }
h3 { font-size: var(--fs-h3) }


.section__title {
    margin-bottom: .25em;
}

.section__title--intro {
    font-weight: var(--fw-reg);
}

.section__title--intro strong {
    display: block;
}

.section__subtitle {
    margin: 0;
    font-size: var(--fs-h3);
}

.section__subtitle--intro,
.section__subtitle--about {
    background: var(--clr-accent);
    padding: .25em 1em;
    font-family: var(--ff-secondary);
    margin-bottom: 1em;
}

.section__subtitle--work {
    color: var(--clr-accent);
    font-weight: var(--fw-bold);
    margin-bottom: 2em;
}

.section__subtitle--project {
    color: var(--clr-accent);
    font-weight: var(--fw-bold);
}

/* header */

header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.logo {
    max-width: 100px;
}

.nav {
	width: 30vw;
	-webkit-box-shadow: 5px 5px var(--clr-dark);
	box-shadow: 5px 5px var(--clr-dark);
    position: absolute;
    background: var(--clr-accent);
    color: var(--clr-accent);
    top: 10;
    right: 100vw;
    z-index: 999;
	-webkit-transition: left 0.10s ease-in;
	-o-transition: left 0.10s ease-in;
    transition: left 0.10s ease-in;

}
.close {
	color: var(--clr-dark);
	padding: 8px 9px;
	align: left;
	left: 100vw;
	justify-content: flex-end;
	cursor: pointer;
}
.nav.show {
	top: 30;
	right: 0
}
.menu-button {
    padding: .5em;
    position: relative;
    z-index: 1000;
    cursor: pointer;
	left: 1em;
}


.menu-list {
    list-style: none;
	top: 30;
    color: var(--clr-accent);
    font-weight: var(--fw-bold);
    text-decoration: none;	
    display: flex;
    height: 10%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
	box-shadow: 1px 1px var(--clr-dark);
}

.menu-items {
	top: 10;
	padding: 0;
	margin: 0;
	color: var(--clr-accent);
	border: 1px;
	box-shadow: 5px 5px var(--clr-dark);

}
.nav__list {
    list-style: none;
    display: flex;
    height: 10%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;
}



.nav__link {
    color: inherit;
    font-weight: var(--fw-bold);
    font-size: var(--fs-h2);
    text-decoration: none;
}

.nav__link:hover {
    color: var(--clr-accent);
}

.nav-toggle {
    padding: .5em;
    background: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 1000;
}

.nav-open .nav {
    transform: translateX(0);
}

.nav-open .nav-toggle {
    position: fixed;
}

.nav-open .hamburger {
    transform: rotate(.625turn);
}

.nav-open .hamburger::before {
    transform: rotate(90deg) translateX(-6px);
}

.nav-open .hamburger::after {
    opacity: 0;
}






.hamburger {
    display: block;
    position: relative;
}

.hamburger,
.hamburger::before,
.hamburger::after {
    background: var(--clr-accent);
    width: 2em;
    height: 3px;
    border-radius: 1em;
    transition: transform 250ms ease-in-out;
}


.hamburger::before,
.hamburger::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
}

.hamburger::before { top: 10px; }
.hamburger::after { bottom: 10px; }


/*  Intro section  */

.intro {
    position: relative;
}

.intro__img {
    box-shadow: var(--bs);
}

.section__subtitle--intro {
    display: inline-block;
}

@media (min-width: 600px) {
    .intro {
        display: grid;
        width: min-content;
        margin: 0 auto;
        grid-column-gap: 1em;
        grid-template-areas: 
            "img title"
            "img subtitle";
        grid-template-columns: min-content max-content;
    }
    
    .intro__img {
        grid-area: img;
        min-width: 250px;
        position: relative;
        z-index: 2;
    }    
    
    .section__subtitle--intro {
        align-self: start;
        grid-column: -1 / 1;
        grid-row: 2;
        text-align: right;
        position: relative;
        left: -1.5em;
        width: calc(100% + 1.5em);
    }
}



/*  My services section  */

.my-services {
    background-color: var(--clr-dark);
    background-image: url(./img/services-bg.jpg);
    background-size: cover;
    /* background-blend-mode: multiply; */
    color: var(--clr-light);
    text-align: center;
}

.section__title--services {
    color: var(--clr-accent);
    position: relative;
}

.section__title--services::after {
    content: '';
    display: block;
    width: 2em;
    height: 1px;
    margin: 0.5em auto 1em;
    background: var(--clr-light);
    opacity: 0.25;
}

.services {
    margin-bottom: 4em;
}

.service {
    max-width: 500px;
    margin: 0 auto;
}

@media (min-width: 800px) {
    .services {
        display: flex;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .service + .service {
        margin-left: 2em;
    }
}


.about-me {
    max-width: 1100px;
    margin: 0 auto;
}

.about-me__img {
    box-shadow: var(--bs);
}

@media (min-width: 600px) {
    .about-me {
        display: grid;
        grid-template-columns: 1fr 250px;
        grid-template-areas: 
            "title img"
            "subtitle img"
            "text img";
        grid-column-gap: 2em;
    }
    
    .section__title--about {
        grid-area: title;
    }
    
    .section__subtitle--about {
        grid-column: 1 / -1;
        grid-row: 2;
        position: relative;
        left: -1em;
        width: calc(100% + 2em);
        padding-left: 1em;
        padding-right: calc(200px + 4em);
    }
    
    .about-me__img {
        grid-area: img;
        position: relative;
        z-index: 2;
    }
}

/* My Work */

.my-work {
    background-color: var(--clr-dark);
    color: var(--clr-light);
    text-align: center;    
}

.my-responsibilities {
    background-color: var(--clr-dark);
    color: var(--clr-light);
	margin-left: 2em;
    text-align: left;    
}

.my-projects {
    background-color: var(--clr-dark);
    color: var(--clr-light);
    padding: 1em 1em;
    text-align: center;    
}

.portfolio {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.portfolio__item {
    background: var(--clr-accent);
    overflow: hidden;
}

.portfolio__img {
    transition: 
        transform 750ms cubic-bezier(.5, 0, .5, 1),
        opacity 250ms linear;
}

.portfolio__item:focus {
    position: relative;
    z-index: 2;
}

.portfolio__img:hover,
.portfolio__item:focus .portfolio__img {
    transform: scale(1.2);
    opacity: .5;
}


/* footer */

.footer {
    background: #111;
    color: var(--clr-accent);
    text-align: center;
    padding: 2.5em 0;
    font-size: var(--fs-h3);

}

.footer a { 
    color: inherit;
    text-decoration: none;
}

.footer__link {
    font-weight: var(--fw-bold);
}

.footer__link:hover,
.social-list__link:hover {
    opacity: .7;
}

.footer__link:hover {
    text-decoration: underline;
}

.social-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 2em 0 0;
    padding: 0;
}

.social-list__item {
    margin: 0 .5em;
}

.social-list__link {
    padding: .5em;
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: transparent;
  color: white;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:link {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

.topnav a:visited {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

.topnav a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

.topnav a:active {
  color: white;
  background-color: transparent;
  text-decoration: underline;
}

.marquee {
    width: 450px;
	line-height: 50px;
	background-color: red;
	color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}
.marquee p {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 15s linear infinite;
}
@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}